<template>
  <div>
    <portal to="breadcrumb-right">
      <b-button
        v-if="isHost && $can('Core.Files.Manage')"
        class="mr-1"
        size="sm"
        variant="secondary"
        @click="openCreateFileModal"
      >
        New file...
      </b-button>
    </portal>

    <b-overlay :show="isLoading">
      <b-card class="mb-3">
        <b-card-header>
          <b-card-title>
            ONEInsight Agent
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <p>Version: {{ agentFiles[0].version }} - Release date: {{ agentFiles[0].releaseDate | formatDate }}</p>
          <div class="d-flex align-items-center mb-3">
            <b-button
              v-if="agentWindows"
              variant="primary"
              :href="agentWindows.downloadLink"
              target="_blank"
              class="mr-2"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-1"
              />
              Download for Windows
            </b-button>
            <b-button
              v-if="agentLinux"
              variant="primary"
              :href="agentLinux.downloadLink"
              target="_blank"
              class="mr-2"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-1"
              />
              Download for Linux
            </b-button>
            <b-button
              variant="outline-primary"
              href="https://oneinsightagentdoc.mentiq.com"
              target="_blank"
            >
              <feather-icon
                icon="LinkIcon"
                class="mr-1"
              />
              Documentation
            </b-button>
          </div>

          <p>The ONEInsight Agent is modular. Download the modules for the systems you want to monitor and install them in your ONEInsight Agent. Refer to the <b-link
            href="https://oneinsightagentdoc.mentiq.com"
            target="_blank"
          >
            documentation
          </b-link> for more information.</p>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>
                    Module
                  </th>
                  <th>Version</th>
                  <th>Release Date</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="agentModule in agentModuleFiles"
                  :key="agentModule.id"
                >
                  <td>
                    {{ agentModule.displayName }}<br>
                    <small class="text-muted">{{ agentModule.shortDescription }}</small>
                  </td>
                  <td>
                    {{ agentModule.version }}
                  </td>
                  <td>
                    {{ agentModule.releaseDate | formatDate }}
                  </td>
                  <td>
                    <b-button
                      class="mx-1"
                      variant="primary"
                      size="sm"
                      :href="agentModule.downloadLink"
                      target="_blank"
                    >
                      <feather-icon icon="DownloadIcon" />
                      Download
                    </b-button>
                    <b-button
                      v-if="isHost && $can('Core.Files.Manage')"
                      class="mx-1"
                      size="sm"
                      variant="secondary"
                      @click="openManageModal(agentModule.id)"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card-body>
      </b-card>

      <b-card>
        <b-card-header>
          <b-card-title>
            Other Downloads
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    File
                  </th>
                  <th>Size</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="file in miscFiles"
                  :key="file.id"
                >
                  <td>
                    {{ file.displayName }}
                    <template v-if="file.shortDescription">
                      <br>
                      <small class="text-muted">{{ file.shortDescription }}</small>
                    </template>
                  </td>
                  <td>
                    {{ file.size | prettyBytes }}
                  </td>
                  <td>
                    <b-button
                      variant="primary"
                      size="sm"
                      :href="file.downloadLink"
                      target="_blank"
                    >
                      <feather-icon icon="DownloadIcon" />
                      Download
                    </b-button>
                    <b-button
                      v-if="isHost && $can('Core.Files.Manage')"
                      size="sm"
                      variant="secondary"
                      class="ml-1"
                      @click="openManageModal(file.id)"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card-body>
      </b-card>

    </b-overlay>

    <ManageModal
      ref="manageModal"
      @updated="loadData"
    />

    <CreateFileModal
      ref="createFileModal"
      @created="loadData"
    />

  </div>
</template>

<script>

import {
  BCard, BButton, BOverlay, BCardHeader, BCardTitle, BCardBody, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import FileManagerService from '@/service/fileManager.service'
import ManageModal from './ManageModal.vue'
import CreateFileModal from './CreateFileModal.vue'

export default {
  components: {
    BCard, BButton, BOverlay, ManageModal, CreateFileModal, BCardHeader, BCardTitle, BCardBody, BLink,
  },
  data() {
    return {
      isLoading: false,
      files: [],
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    agentFiles() {
      return this.files.filter(file => file.fileCategory === 0)
    },
    agentWindows() {
      const result = this.agentFiles.filter(file => file.displayName.indexOf('win') > -1)
      if (!result || result.length === 0) {
        return null
      }

      return result[0]
    },
    agentLinux() {
      const result = this.agentFiles.filter(file => file.displayName.indexOf('linux') > -1)
      if (!result || result.length === 0) {
        return null
      }

      return result[0]
    },
    agentModuleFiles() {
      return this.files.filter(file => file.fileCategory === 1).sort((a, b) => a.displayName.localeCompare(b.displayName))
    },
    miscFiles() {
      return this.files.filter(file => file.fileCategory === null)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      FileManagerService.getAllListAsync()
        .then(result => {
          this.files = result.items
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    openManageModal(fileId) {
      this.$refs.manageModal.show(fileId)
    },
    openCreateFileModal() {
      this.$refs.createFileModal.show()
    },
  },
}

</script>
